import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false
import '@/assets/styles.css'
import vueCustomElement from 'vue-custom-element'
Vue.use(vueCustomElement)

Vue.customElement('embed-poll', App)

// new Vue({
//   render: h => h(App),
// }).$mount('#app')
