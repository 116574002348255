<template>
  <div class="bg-gray-3000 p-4 " v-if="poll_data" :style="cssProps">
    <div class="bg-green-3000 question-label">
      {{ poll_data.label }}
      <!--      What kind of polls would you like on your site?-->
    </div>

    <div class="bg-red-3000 mt-2" v-if="done_poll==0">
      <div class="space-y-2 text-center">
        <div v-for="(option) in poll_options" :key="option.label" @click="vote(option.id);option.clicked=true"
             class="rounded-full border px-2 py-1.5 text-sm alternative-container cursor-pointer relative leading-normal">
          <div class="alternative-prev-label-container"></div>
          <div class="alternative-label">{{ option.label }}</div>
          <svg v-if="option.clicked" class="animate-spin absolute -ml-1 mr-3 h-5 w-5 text-white top-2 right-0.5"
               xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>
      </div>
    </div>

    <div class="bg-red-3000 mt-2" v-else>
      <div class="space-y-3 text-center">
        <div v-for="option in poll_data.options" :key="option.label" class="answer-container flex items-center" :class="[done_poll && option.id === answer_id ?'answer':'']">
          <!--          <div class="rounded-full border border-blue-500 px-2 py-1 text-blue-500 cursor-pointer hover:bg-blue-100 w-full">{{option.name}}</div>-->
          <div class="answer-label-container w-full items-center flex relative">
            <div class="progress-bar rounded-sm  text-center  leading-normal text-transparent absolute" :styles="CustomColor"
                 :style="{width: option.result.percentage_rel*100+'%'}">&nbsp; . &nbsp; &nbsp;
            </div>
            <div class="answer-label relative text-sm leading-none pl-2">{{ option.label }}</div>
          </div>
          <div class="answer-percentage w-10 px-2 py-1">{{ Math.round(option.result.percentage * 100) * 100 / 100 }}%
          </div>
        </div>
      </div>
    </div>

    <!--    <div class="bg-red-3000 mt-2">-->
    <!--      <div class="space-y-1 text-center">-->
    <!--        <div v-for="option in options" :key="option.name" class="flex items-center">-->
    <!--          &lt;!&ndash;          <div class="rounded-full border border-blue-500 px-2 py-1 text-blue-500 cursor-pointer hover:bg-blue-100 w-full">{{option.name}}</div>&ndash;&gt;-->
    <!--          <div class="w-full items-center flex">-->
    <!--            <div class="absolute text-sm leading-none pl-2 text-gray-800">{{option.name}}</div>-->

    <!--            <div class="bg-blue-400 rounded-sm  text-center h-6" :style="{width: option.percentage*100 +'%'}"></div>-->
    <!--          </div>-->
    <!--          <div class="w-10">{{option.results*100}}%</div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>
<style lang="scss">
.animated {
  .alternative-container {
    @apply animate-pulse;
    animation-iteration-count: 3 !important;
    /*-webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;*/
    /*animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;*/
    /*animation-duration: 2s !important;*/
    /*animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1) !important;*/
    /*animation-delay: 0s !important;*/
    /*animation-iteration-count: 2 !important;*/
    /*animation-direction: normal !important;*/
    /*animation-fill-mode: none !important;*/
    /*animation-play-state: running !important;*/
    /*animation-name: pulse !important;*/
  }
}

//general
.embedpolls {
  @apply font-sans;
}
.custom-css{
  .embedpolls {
    .question-label {
    }

    .alternative-container {
    }

    .answer-label {
    }

    .answer-percentage {
    }

    .progress-bar {

    }
  }
}
//dark
.dark {
  .embedpolls {
    .question-label {
      @apply text-white;
    }

    .alternative-container {
      @apply text-white border-white border border-solid hover:bg-blue-100 bg-opacity-25 hover:bg-opacity-25;
    }

    .answer-label {
      @apply text-white;
    }

    .answer-percentage {
      @apply text-white;
    }

    .progress-bar {
      @apply bg-gray-600 text-gray-800 border-white border;
      border: 5px solid transparent !important;
      background-clip: padding-box !important;
      border-radius: 5px !important;
      box-shadow: 0 0 0px 0.2px #fff !important;
      padding: 0px 0px !important;
      color: #ffffff00 !important;
    }
  }
}

//specific
/*@use postcss-nested;*/
embed-poll:not(.custom-css){
  .embedpolls {
    .question-label {
      @apply font-sans;
    }

    .question-label {
      @apply text-gray-700;
    }

    .alternative-container {
      @apply text-blue-500 border-blue-500 border border-solid hover:bg-blue-100 ;
    }

    .answer-label {
      @apply text-gray-800;
    }

    .answer-percentage {
    }

    .progress-bar {
      @apply pb-1 pt-1;
      @apply border-blue-400 bg-gray-200;
    }
    .answer{
      .progress-bar {
        @apply border-blue-400 bg-blue-400;
      }
    }
  }
}

embed-poll.custom-color {
  .embedpolls {
    .question-label {
      //color:var(--main-color)!important;
      //@apply text-gray-700;
      color: var(--main-color) !important;
    }

    .alternative-container {
      //@apply border-white border border-solid bg-opacity-25 hover:bg-opacity-5;
      @apply bg-opacity-25 hover:bg-opacity-50;
      border-color: var(--main-color) !important;
      color: var(--main-color) !important;
      &:hover{
        background-color: var(--main-color);
      }
    }

    .answer-label {
      @apply text-white;
      color: var(--main-color) !important;

    }

    .answer-percentage {
      color: var(--main-color) !important;
    }

    .progress-bar {
      @apply border-white border;
      border: 5px solid transparent !important;
      background-clip: padding-box !important;
      border-radius: 5px !important;
      box-shadow: 0 0 0px 1px var(--main-color) !important;
      padding: 0px 0px !important;
      color: var(--main-color) !important;
      background-color: unset;
    }
    .answer{
      .progress-bar {
        @apply border-2;
        box-shadow: 0 0 0px 2px var(--main-color) !important;
        background-color: unset;
        //mix-blend-mode: difference;
        //@apply bg-opacity-50 bg-gray-200;
        @apply bg-opacity-10 bg-black;
        //@apply border-blue-400 bg-opacity-50;
        //background-color: var(--main-color);
      }
    }
  }
}

.dark {
  .custom-color {
    .embedpolls {
      .question-label {
        //color:var(--main-color)!important;
        //@apply text-gray-700;
        color: var(--dark-color) !important;
      }

      .alternative-container {
        @apply border-white border border-solid hover:bg-white bg-opacity-25 hover:bg-opacity-5;
        border-color: var(--dark-color) !important;
        color: var(--dark-color) !important;
      }

      .answer-label {
        @apply text-white;
        color: var(--dark-color) !important;

      }

      .answer-percentage {
        color: var(--dark-color) !important;
      }

      .progress-bar {
        @apply border-white border;
        border: 5px solid transparent !important;
        background-clip: padding-box !important;
        border-radius: 5px !important;
        box-shadow: 0 0 0px 1px var(--dark-color) !important;
        padding: 0px 0px !important;
        color: var(--dark-color) !important;
        background-color: unset;
      }
      .answer{
        .progress-bar {
          @apply border-2;
          box-shadow: 0 0 0px 2px var(--dark-color) !important;
          background-color: unset;
          //mix-blend-mode: difference;
          //@apply bg-opacity-50 bg-gray-200;
          @apply bg-opacity-10 bg-white;
          //@apply border-blue-400 bg-opacity-50;
          //background-color: var(--main-color);
        }
      }
    }
  }
}
</style>
<script>
export default {
  components: {},
  props:
      {
        poll_uuid: {
          type: String,
          required: true,
        },
        color: {
          type: String,
          default: '#6366f1'
        }
        ,
        darkColor: {
          type: String,
          default: 'white'
        }
      },
  data() {
    return {
      options: [
        {
          id: 1, name: 'Twitter Polls', results: 0.5, percentage: 1
        },
        {
          id: 2, name: 'Google Forms', results: 0.2, percentage: 0.4
        },
        {
          id: 3, name: 'Wut?', results: 0.3, percentage: 0.6
        },
      ],
      poll_data: null,
      answer_id: null,
      done_poll: 0,
      poll_options: [],
      main_color: this.color,
      dark_color: this.darkColor
    }
  },
  computed: {
    cssProps() {
      return {
        '--main-color': this.main_color,
        '--dark-color': this.dark_color,
      }
    }
  },
  watch: {},
  mounted() {
    this.getPoll()
  },
  created() {
    // console.log(localStorage);
    // if (localStorage.questions){
    //   let questions=JSON.parse(localStorage.questions);
    //   // questions.push(JSON.stringify([this.poll_uuid,false]))
    //   questions.push([this.poll_uuid,false])
    //   localStorage.setItem('questions',JSON.stringify(questions))
    //
    //
    // }else{
    //   localStorage.setItem('questions',JSON.stringify([this.poll_uuid,false]))
    // }
    // // localStorage.setItem(this.poll_uuid, 0);
    // console.log(localStorage);
    console.log(this.poll_uuid)
    console.log(localStorage.getItem(this.poll_uuid))
    if (localStorage.getItem(this.poll_uuid)) {
      this.done_poll = localStorage.getItem(this.poll_uuid)
      this.answer_id = localStorage.getItem(this.poll_uuid)*1
    } else {
      localStorage.setItem(this.poll_uuid, 0)
    }

  },
  methods: {
    CustomColor() {
      return ''
    },
    async apiGetPollCall() {
      const res = await fetch(`${process.env.VUE_APP_API}/show/` + this.poll_uuid, {
        method: "POST"
      });
      const data = await res.json();
      return data.poll
    },
    async getPoll() {
      const data = await this.apiGetPollCall();
      this.poll_data = data
      this.poll_options = data.options;
      this.poll_options.forEach((o, i) => {
        this.$set(this.poll_options[i], "clicked", false);
      });
    },
    async vote(option_choice) {
      let poll = {}
      poll.uuid = this.poll_uuid;
      poll.option = option_choice
      // eslint-disable-next-line no-unused-vars
      const res = await fetch(`${process.env.VUE_APP_API}/vote/` + this.poll_uuid, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(poll)
      });
      // const data = await res.json();
      await this.getPoll();
      localStorage.setItem(this.poll_uuid, option_choice)
      this.answer_id = option_choice*1
      this.done_poll = true
      // this.poll_data = data.poll;
    }
  }

}
</script>
