<template>
<!--  <div id="app" class="flex w-full items-center content-center justify-center h-screen">-->
    <Question :poll_uuid="poll" :color="color" :darkColor="darkColor" class="embedpolls"/>
<!--  </div>-->
</template>

<script>
import Question from './components/question.vue'

export default {
  name: 'App',
  props: ['poll','color', 'darkColor'],
  components: {
    Question
  },
  mounted() {
  }
}
</script>

<style>

</style>
